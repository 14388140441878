<template>
  <div id="ppt">
    <div class="box">
      <div class="main">
        <iframe
          id="iframe1"
          width="100%"
          height="100%"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="model"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return { model: "" };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        console.log(res);

        let obj = JSON.parse(res.data.file_path);
        // http://192.168.1.210:8093
        this.model =
          `/threejs/a.html?names=` + obj.file_name + "&" + "path=" + obj.path;
      });
    },
  },
};
</script>
<style lang="less" scoped>
#ppt {
  height: 100%;
  .box {
    height: calc(100vh - 0.625in);
    // min-height: 1020px;
    position: relative;
  }
  .main {
    width: 91%;
    height: 744px;
    // background-color: #62e6db;
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 9%);
  }
}
</style>